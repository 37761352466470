<template>
  <div class="birth">
    <div class="title">請輸入你的出生年月日</div>
    <InputPad @submit="submit" @input="inputChange"></InputPad>
    <input @focus="input=''" class="input" type="text" :value="input" readonly />
    <div class="desc">
      西元年月日
      共
      <span>8</span>
      個數字
    </div>
    <div class="limit">
      <span>&#10056 國曆</span>
      <span>&#10056 台灣時間</span>
      <span>&#10056 中間無空格</span>
    </div>
    <div class="sample">範例：19881231</div>
    <Modal v-if="modal.show" :payload="modal" @close="modal.show=false" />
  </div>
</template>

<script>
import InputPad from "@/components/inputPad";
import Modal from "@/components/modal";
import ModalEvent from "@/mixins/modal";
import Util from "@/mixins/util";
const dateFns = require("date-fns");
const liff = window.liff;

export default {
  name: "Input",
  components: {
    InputPad,
    Modal,
  },
  mixins: [ModalEvent, Util],
  data: function () {
    return {
      liffId: "1656896964-vZQ33ZpB",
      title: "輸入你的生日",
      input: "",
    };
  },
  async created() {
    await this.setLiff(this.liffId);
  },
  computed: {},
  methods: {
    inputChange(input) {
      this.input = input;
    },
    async submit() {
      let date = this.input;
      if (date.length < 8) {
        this.modalEvent("birthErrorB");
        return false;
      }
      let year = parseInt(date.slice(0, 4));
      let month = parseInt(date.slice(4, 6));
      let day = parseInt(date.slice(6, 8));
      if (
        year < 1942 ||
        year > 2021 ||
        month < 0 ||
        month > 12 ||
        day < 0 ||
        day > 31
      ) {
        this.modalEvent("birthErrorB");
        return false;
      }

      let checkDate = new Date();
      checkDate.setFullYear(year, month - 1, day);
      if (
        checkDate.getFullYear() != year ||
        checkDate.getMonth() != month - 1 ||
        checkDate.getDate() != day
      ) {
        this.modalEvent("birthErrorB");
        return false;
      }

      // todo

      await liff.sendMessages([
        {
          type: "text",
          text: date,
        },
      ]);

      // date = date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
      // console.log(date);
      liff.closeWindow();
    },
  },
};
</script>
<style lang="scss" scoped>
.birth {
  font-family: "Noto Sans TC", sans-serif !important;
  text-align: center;

  .input {
    border: 1px solid #666;
    border-radius: 30px;
    width: 150px;
    height: 36px;
    line-height: 36px;
    font-size: 26px;
    text-align: center;
  }
  .title {
    color: #f15a24;
    font-size: 24px;
    margin: 0px 0px 10px 0px;
  }
  .desc {
    color: #666;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
    span {
      font-size: 28px;
    }
  }
  .limit {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    span {
      margin: 0px 10px;
    }
  }
  .sample {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
  }
}
</style>
